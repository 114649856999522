<template>
    <div class="relative">
        <form
            class="flex flex-col items-center px-8 xsm:px-0"
            method="post"
            @submit.prevent="submit"
            novalidate
        >
            <BasicInput
                v-model="formData.phone"
                ref="phone"
                :is-required="true"
                :is-loading="false"
                input-type="tel"
                input-id="login-phone"
                input-label="Телефон"
                :input-classes="formErrors.phone ? '!border-roman' : ''"
                :tooltip-error-text="formErrors.phone"
                v-maska="{
                    mask: ['+7 ### ###-##-##', '+7 F### ###-##-##'],
                    tokens: {
                        '#': {
                            pattern: /[0-9]/
                        },
                        'F': {
                            pattern: /[0-9]/,
                            optional: true,
                            transform: (char: string) => ' '
                        }
                    }
                }"
                @keypress="removeError('phone')"
                @change="removeError('phone')"
                autocomplete="tel"
                @input="formatPhoneOnInput($event)"
                class="w-full max-w-[15.375rem] h-full mt-4 first:mt-0"
            />
            <button
                class="button !text-white bg-black max-w-[15.375rem] mt-4 w-full !px-0 code-request disabled:pointer-events-none disabled:bg-black/30"
                :disabled="formData.phone.length !== 16 || !isAcceptTheTerms"
                type="submit"
                @click="sendingCodeAndCheckingParameters(
                            $emit,
                            isAcceptTheTerms,
                            'editEnteringConfirmationCodeFromSms')"
            >
                {{ textButtonEnterCode }}
            </button>
            <div
                class="text-pink-antique text-center text-caption mt-2"
                v-show="formErrors.code"
            >
                {{ formErrors.code }}
            </div>
            <div
                class="text-shuttle-gray text-center text-caption mt-1"
                v-if="timer"
            >
                Отправить повторно через {{ timer }} сек.
            </div>
            <a
                href="/login/?pass"
                @click="$emit('editFormLoginWithYourEmailAndPassword', true)"
                class="mt-10 flex justify-center cursor-pointer w-min whitespace-nowrap mx-auto fade-hover-effect"
            >
                Войти с паролем
            </a>
            <div class="flex gap-2 items-start w-full mx-auto color-shuttle-gray mt-10">
                <div
                    class="relative min-w-8 h-4 rounded-lg cursor-pointer"
                    :class="{
                        [isAcceptTheTerms
                            ? 'border-dark-green'
                            : 'pink-antique']: true,
                        [isAcceptTheTerms
                            ? 'bg-dark-green'
                            : 'bg-pink-antique']: true,
                    }"
                    @click="toggleLoginMethod()"
                >
                    <div class="absolute w-4 h-4 bg-white rounded-full left-0 top-0 transition-all border-2 border-solid"
                        :class="{
                            'left-4': isAcceptTheTerms,
                            [isAcceptTheTerms
                                ? 'border-dark-green'
                                : 'pink-antique']: true,
                        }"></div>
                </div>
                <div class="text-caption text-shuttle-gray">
                    Cоглашаюсь с условиями
                    <a class="text-caption text-black no-underline fade-hover-effect hover:text-shuttle-gray" href="/license/" target="_blank">
                        Политики конфиденциальности
                    </a>
                    и даю
                    <a class="text-caption text-black no-underline fade-hover-effect hover:text-shuttle-gray" href="/personalnye-dannye/" target="_blank">
                        Согласие на обработку персональных данных
                    </a>
                    и получение сообщений.
                </div>
            </div>
        </form>
        <DropdownScreenModal
            relation="login-page"
            :use-slots-condition="true"
            v-if="isEnteringConfirmationCodeFromSms"
        >
            <template #default>
                <div class="p-8 bg-white flex flex-col rounded-2xl dropdown-screen__content-width">
                    <h2 class="text-fluid-h2 uppercase text-center font-medium tracking-[2px]">
                        Введите<br> код из смс
                    </h2>
                    <form
                        method="post"
                        @submit.prevent="submit"
                        novalidate
                        class="bg-white mt-6"
                    >
                        <div class="flex flex-col text-shuttle-gray items-center text-body">
                            <span>Номер получателя:</span>
                            <span>{{ formData.phone }}</span>
                        </div>

                        <BasicInput
                            v-model="formData.code"
                            ref="code"
                            :is-required="true"
                            :is-loading="false"
                            input-type="tel"
                            :input-classes="formErrors.code ? '!border-roman' : ''"
                            input-id="code"
                            input-label="Код"
                            @input="handleInputCode"
                            :maxlength="6"
                            class="w-full h-full mt-4 first:mt-0"
                        />
                        <div
                            class="text-pink-antique text-center text-caption mt-2"
                            v-show="formErrors.code"
                        >
                            {{ formErrors.code }}
                        </div>
                        <div
                            class="text-shuttle-gray text-center text-caption mt-2"
                            v-if="timer"
                        >
                            Отправить повторно через {{ timer }} сек.
                        </div>
                        <div
                            class="mt-2 flex justify-center underline cursor-pointer w-min whitespace-nowrap mx-auto"
                            v-if="!timerStatus && codeRequestAgain"
                            @click="sendingCodeAndCheckingParameters(
                                $emit,
                                isAcceptTheTerms,
                                'editEnteringConfirmationCodeFromSms'
                            )"
                        >
                            Отправить повторно
                        </div>
                        <a
                            href="/login/?pass"
                            class="mt-4 flex justify-center underline cursor-pointer w-min whitespace-nowrap mx-auto"
                        >
                            Войти с паролем
                        </a>
                    </form>
                </div>
                <TheLoader
                    v-if="isEnteringConfirmationCodeFromSms"
                    :is-loading="isCodeSending"
                />
            </template>
        </DropdownScreenModal>
        <TheLoader
            v-if="!isEnteringConfirmationCodeFromSms"
            :is-loading="isCodeSending"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, reactive, ComponentPublicInstance, watch } from "vue"

import BasicInput from "~vue/misc/BasicInput.vue"
import { fetchRequest } from "~/helpers/fetch"
import TheLoader from "~vue/header/profile/TheLoader.vue"
import { getCookie } from "~/helpers/cookies"
import { JsonApiSingle, JsonApiError } from "~/interfaces/json-api"
import { AuthLogin } from "~/interfaces/auth/login"
import { WindowCustom } from '~/interfaces/window'
import DropdownScreenModal      from '~vue/dropdown-wrappers/DropdownScreenModal.vue'
import { toggleElement } from "~/client/vanilla-components/dropdown-screen"
import {useBodyLock} from "~/helpers/body-lock";

type BasicInputInstance = ComponentPublicInstance<typeof BasicInput> | null
type errorsKey = keyof typeof formErrors

const { unLockBody } = useBodyLock()

let isAcceptTheTerms = ref(true)
let codeRequestAgain = ref(false)
let timer = ref(0)
const isCodeSending = ref(false)

let formData = reactive({
    phone: getCookie("phoneUser") ?? "",
    email: "",
    password: "",
    code: "",
})

if (getCookie("timerStart") && getCookie("phoneUser")) {
    let cookieTime = getCookie("timerStart")
    let time = new Date().getTime()
    if (60 > (time - Number(cookieTime)) / 1000) {
        let durration = Math.trunc(60 - (time - Number(cookieTime)) / 1000)
        codeRequestAgain = ref(true)
        timer = ref(durration)
        const intervalId = setInterval(() => {
            durration--
            if (durration <= 0) {
                clearInterval(intervalId)
                timer.value = 0
            } else {
                timer.value = durration
            }
        }, 1000)
    }
}

const props = defineProps({
    inDevelopment: {
        required: false,
        type: Boolean,
        default: false,
    },
    isEnteringConfirmationCodeFromSms: Boolean,
    timerStatus: Boolean,
    textButtonEnterCode: String,
    isFormLoginWithYourEmailAndPassword: Boolean,
})

watch(() => props.isEnteringConfirmationCodeFromSms, () => {
    setTimeout(() => togglePopup(), 500)
})

const togglePopup = () => {
    const dropdown = document.querySelector<HTMLElement>('.dropdown-screen[data-relation="login-page"]')

    if (!dropdown) return

    toggleElement(dropdown)
}

const formatPhoneOnInput = (event: Event) => {
    const inputElement = event.target as HTMLInputElement
    const inputValue = inputElement.dataset?.maskRawValue

    if (!inputValue?.length) return void 0

    if (inputValue.length > 10) {
        event.preventDefault()

        formData.phone = `+7${inputValue.substring(1, 11)}`

        setTimeout(() => {
            inputElement.dispatchEvent(
                new CustomEvent("mask", { bubbles: true })
            )
        })
    }
}

const handleInputCode = () => {
    if (formData.code.length == 6) {
        const body = new FormData()

        const emitFunctuion = () => {
            const windowCustom: WindowCustom = window

            if (windowCustom.dataLayer) {
                windowCustom.dataLayer.push({'event': 'authorization'})
            }

            formErrors.code = ""

            window.location.href = "/lk/"
        }

        const errorFunctuion = (errorText = "Не верный код") => {
            formErrors.code = errorText
        }

        body.append("phone", formData.phone)
        body.append("code", formData.code)
        body.append("ajax", "1")

        sendAuthorizationDataViaSms(
            emitFunctuion,
            errorFunctuion,
            "check-code",
            body
        )
    } else if (formData.code.length > 6) {
        formData.code = formData.code.slice(0, 6)
    }
}

const sendAuthorizationDataViaSms = async (
    callback: () => void,
    errorFunctuion: (errorText: string) => void,
    metchod: string,
    body: FormData,
    methodValue: "POST" | "GET" = "POST"
) => {
    try {
        isCodeSending.value = true

        const response = await fetchRequest("/login/?ajax=1&action=" + metchod, {
            method: methodValue,
            body,
        })

        if (response.data.status == 403) {
            location.reload()
        }

        if (response.data.status != 200) {
            errorFunctuion(response.data.error)
            throw new Error(`${response.data.error}`)
        }

        const windowCustom: WindowCustom = window

        if (metchod == 'check-code' && windowCustom.dataLayer && response.data.hasCustomerBeenCreated) {
            windowCustom.dataLayer.push({'event': 'registration'})
        }

        callback()
    } catch (error) {
        let errorMessage = "500 от сервера"

        if (error instanceof Error) errorMessage = error.message

        const defaultError: JsonApiError = {
            status: "500",
            code: "500-internal",
            title: errorMessage,
            detail: errorMessage,
        }
        responseErrors.value.push(defaultError)
    } finally {
        isCodeSending.value = false
    }
}

const sendingCodeAndCheckingParameters = async (
    emit: (eventName: string, payload: any) => void,
    isAcceptTheTerms: boolean,
    eventName: string
) => {
    if (isAcceptTheTerms && formData.phone.length == 16) {
        const emitFunctuion = (restartTimer = true) => {
            formErrors.phone = ""
            formErrors.code = ""

            if (restartTimer) {
                startTimer(60000, timer, emit)
            }

            emit(eventName, true)
            setTimeout(() => {
                codeRequestAgain.value = true
            }, 1000)
        }

        const errorFunctuion = (text: string) => {
            let re = /(banIp|banPhone)=([\-0-9]+)/i
            let found = text.match(re)

            if (found && found[1] && found[2]) {
                let timeBan = Number(found[2])
                let texterror = ''
                if (found[1] == "banIp") {
                    texterror = 'Превышен лимит запросов с вашего IP-адреса. Отправка повторного кода в смс будет возможна спустя '
                } else if (found[1] == "banPhone") {
                    texterror = 'Превышен лимит запросов. Отправка кода в смс будет доступна через '
                }

                const intervalId = setInterval(() => {
                    timeBan--
                    if (timeBan > 0) {
                        formErrors.code = texterror + getTimeBanFomat(timeBan)
                    } else {
                        clearInterval(intervalId)
                        formErrors.code = ''
                    }
                }, 1000)

            } else {
                formErrors.code = text
            }
            if (!formData.phone) {
                formErrors.phone = "Введите телефон"
            }
        }

        const body = new FormData()

        body.append("phone", formData.phone)
        body.append("ajax", "1")

        if (timer.value == 0) {
            sendAuthorizationDataViaSms(
                emitFunctuion,
                errorFunctuion,
                "get-code",
                body
            )

            formData.code = ""
        } else {
            emitFunctuion(false)
            togglePopup()
        }
    }
}

let responseErrors = ref<JsonApiError[]>([])

let formErrors = reactive({
    phone: "",
    email: "",
    password: "",
    code: "",
})

const phone = ref<BasicInputInstance>(null)
const email = ref<BasicInputInstance>(null)
const password = ref<BasicInputInstance>(null)

const getTimeBanFomat = (time: number) => {
    let hours = Math.trunc(time / 60 / 60)
    let min = Math.trunc((time - hours * 60 * 60) / 60)
    let sec = Math.trunc(time - hours * 60 * 60 - min * 60)

    let res = ""

    if (hours > 0) {
        res += hours + " ч. "
    }

    if (min > 0) {
        res += min + " мин. "
    }

    if (sec > 0) {
        res += sec + " сек. "
    }

    return res
}

const toggleLoginMethod = (forcedValue?: boolean) => {
    if (forcedValue) {
        isAcceptTheTerms.value = forcedValue
    } else {
        isAcceptTheTerms.value = !isAcceptTheTerms.value
    }
}

const validateForm = () => {
    let eagerToFocusElement: HTMLInputElement | null = null
    let status = true

    if (
        status &&
        isAcceptTheTerms.value &&
        (!formData.email ||
            !validateRegex("^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$", formData.email))
    ) {
        formErrors.email = "Укажите корректный адрес электронной почты"

        eagerToFocusElement = email.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (
        status &&
        !isAcceptTheTerms.value &&
        (!formData.phone ||
            !validateRegex(
                "^\\+7\\s[0-9]{3}\\s[0-9]{3}-[0-9]{2}-[0-9]{2}$",
                formData.phone
            ))
    ) {
        formErrors.phone = "Укажите номер телефона полностью"

        eagerToFocusElement = phone.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (status && !formData.password) {
        formErrors.password = "Поле должно быть заполнено"

        eagerToFocusElement = password.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (eagerToFocusElement) {
        eagerToFocusElement.focus()
    }

    return status
}

const validateRegex = (exspresion: string, string: string) => {
    const regex = new RegExp(exspresion)
    return regex.test(string)
}

const submit = async () => {
    if (!validateForm()) return void 0

    responseErrors.value = []

    try {
        const body = new FormData()

        Object.entries(formData).forEach(([key, value]) => {
            body.append(key, value.toString())
        })

        if (isAcceptTheTerms.value) {
            body.append("submitLogin", "1")
        } else {
            body.append("submitPhone", "1")
        }

        body.append("ajax", "1")

        const response = await fetchRequest("/login/?ajax=1&action=auth", {
            method: "POST",
            body,
        })

        parseResponse(response)
    } catch (error) {
        let errorMessage = "500 от сервера"

        if (error instanceof Error) errorMessage = error.message

        const defaultError: JsonApiError = {
            status: "500",
            code: "500-internal",
            title: "Упс, что-то пошло не так 😱. Попробуйте повторить попозже",
            detail: errorMessage,
        }
        responseErrors.value.push(defaultError)
    }
}

const parseResponse = (payload: JsonApiSingle) => {
    if (payload.errors) {
        responseErrors.value = payload.errors

        return void 0
    }

    const authResult = payload.data as AuthLogin

    if (authResult.attributes.shouldRedirect) window.location.href = '/'
}

const removeError = (key: errorsKey) => {
    formErrors[key] = ""
}

const startTimer = (
    duration: number,
    timer: any,
    emit: (eventName: string, payload: any) => void
): void => {
    emit("editTimerStatus", true)

    const startTime = new Date().getTime()

    const intervalId = setInterval(() => {
        const currentTime = new Date().getTime(),
            elapsedTime = currentTime - startTime,
            remainingTime = duration - elapsedTime

        if (remainingTime <= 0) {
            clearInterval(intervalId)
            timer.value = 0

            unLockBody()

            emit("editTimerStatus", false)
        } else {
            timer.value = Math.ceil(remainingTime / 1000)
            document.cookie = "timerStart=" + startTime + "; path=/;"
            document.cookie = "phoneUser=" + formData.phone + "; path=/;"
        }
    }, 1000)
}
</script>

<style lang="scss">
.button.code-request {
    &:hover {
        @apply bg-black #{!important};
    }
    @media (hover: hover) {
        &:hover {
            @apply bg-[rgba(0,0,0,.30)] #{!important};
        }
    }
}
</style>
